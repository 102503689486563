import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Game from "../components/game"
import AdBlock from "../components/adBlock"

import app from 'gatsby-plugin-firebase-v9.0'
import { getFirestore, getDocs, doc, collection } from "firebase/firestore";

// import firebase from "gatsby-plugin-firebase"
// import { useObjectVal } from "react-firebase-hooks/database"

function IndexPage() {

  // const [data, isLoading] = useObjectVal(firebase.database().ref("games"))

  const [ games, setGames ] = React.useState([]); 

  React.useEffect(() => {

    (async () => {

      const db = getFirestore(app);
      const querySnapshot = await getDocs(collection(db, "games"));

      let _games = [];

      querySnapshot.forEach(i => { 
        
        _games.push({ 
          ...i.data(), 
          id: i.id 
        }) 
      
      });

      setGames(_games);
      console.log(_games);

    })();

  }, [ ])

  return (
    <Layout>
      <div className="w-full p-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4">
        {
          games.map(game => <>
            {(Math.random() > 0.6) && <AdBlock />}
            <Game {...game} />
          </>)
        }
      </div>
    </Layout>
  )
}

export const Head = () => <Seo title="Home" />

export default IndexPage
