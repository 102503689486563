import React from 'react'
import AdSense from 'react-adsense'

const AdBlock = () => {

    if (process.env.NODE_ENV !== 'production') return <div className='w-full h-full rounded bg-slate-50'></div>

    return <AdSense.Google
        client="ca-pub-8460495181619769"
        slot="5304584998"
        style={{ display: 'block' }}
        layout="in-article"
        data-adtest="on"
        format="fluid"
        {...{"data-adtest":"on"}}
    />
}

export default AdBlock